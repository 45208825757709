import Box from '@weave-mui/box';
import Tooltip from '@weave-mui/tooltip';
import { styled } from '@weave-mui/styles';
import TextField from '@weave-mui/text-field';
import Paper from '@weave-mui/paper';

export const GlobalContainer = styled(Box)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
`;

export const GlobalPaperWrapper = styled(Paper)`
  height: 100%;
`;

export const TabWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: hidden;
  padding-top: 1rem;
`;

export const TooltipWrapper = styled(Tooltip)`
  & .MuiTooltipWeave-title {
    word-break: break-word !important;
  }

  & .MuiTooltipWeave-title-truncated {
    white-space: unset !important;
  }

  & .MuiTooltip-tooltip {
    width: unset;
    max-width: 400px;
  }
`;

export const TextFieldsWrapper = styled(TextField)`
  input {
    text-overflow: ellipsis;
  }
`;
