import text from '../../../export-rfa.text.json';
import { RFAExportHistoryItemStatuses } from 'mid-addin-lib';
import { RFAExportHistoryItem } from 'mid-addin-lib';
import { EllipsisSUI, ExportMoveS } from '@weave-mui/icons-weave';
import LinearProgress from '@weave-mui/linear-progress';
import MenuList from '@weave-mui/menu-list';
import MenuItem from '@weave-mui/menu-item';
import Popper from '@weave-mui/popper';
import Typography from '@weave-mui/typography';
import { IconButtonWrapper, NotificationContentWrapper, NotificationWrapper } from '../HistoryTab.styles';
import { timeAgo } from '../utils';
import Paper from '@weave-mui/paper';
import useHistoryItems from './useHistoryItem';
import { historyTabDataTestIds } from 'tests/helpers/dataTestIds';

const NotificationContent: React.FC<{ item: RFAExportHistoryItem }> = ({ item }) => {
  switch (item.status) {
    case RFAExportHistoryItemStatuses.SUCCESS:
      return (
        <NotificationContentWrapper>
          <Typography>
            {text.historyTab.complete}
            {item.rfaFileName}
          </Typography>
        </NotificationContentWrapper>
      );
    case RFAExportHistoryItemStatuses.FAILED:
      return (
        <NotificationContentWrapper>
          <Typography>
            {text.historyTab.failed}
            {item.rfaFileName}
          </Typography>
        </NotificationContentWrapper>
      );
    case RFAExportHistoryItemStatuses.PENDING:
      return (
        <NotificationContentWrapper>
          <Typography>
            {text.historyTab.exporting}
            {item.rfaFileName}
          </Typography>
          <LinearProgress data-testid={historyTabDataTestIds.historyItemProgressBar} />
        </NotificationContentWrapper>
      );
  }
};

const HistoryItem: React.FC<{ item: RFAExportHistoryItem }> = ({ item }) => {
  const { openMenuId, anchorRefs, handleDeleteExportRecord, handleMenuClick, handleOnClose, handleShowLogInFolderClick } =
    useHistoryItems();

  return (
    <NotificationWrapper
      data-testid={`${historyTabDataTestIds.historyItem}-${item.id}`}
      key={item.id}
      action={
        item.status !== RFAExportHistoryItemStatuses.PENDING && (
          <>
            <IconButtonWrapper
              onClick={handleMenuClick(item.id)}
              data-testid={historyTabDataTestIds.historyItemOptionsButton}
            >
              <EllipsisSUI />
            </IconButtonWrapper>
            <Popper
              anchorEl={anchorRefs.current[item.id]}
              open={openMenuId === item.id}
              placement="bottom-end"
              onClose={handleOnClose(item.id)}
              arrow
            >
              <Paper elevation="low">
                <MenuList>
                  <MenuItem
                    data-testid={historyTabDataTestIds.showLogInFolderButton}
                    disabled={!item.logFilePath}
                    onClick={handleShowLogInFolderClick(item.logFilePath)}
                  >
                    {text.historyTab.showLogInFolder}
                  </MenuItem>
                  <MenuItem
                    data-testid={historyTabDataTestIds.deleteHistoryItemButton}
                    onClick={handleDeleteExportRecord(item.id)}
                  >
                    {text.historyTab.deleteExportRecord}
                  </MenuItem>
                </MenuList>
              </Paper>
            </Popper>
          </>
        )
      }
      icon={item.status === RFAExportHistoryItemStatuses.PENDING && <ExportMoveS />}
      timestamp={timeAgo(item.updatedAt)}
      severity={
        item.status === RFAExportHistoryItemStatuses.SUCCESS
          ? 'success'
          : item.status === RFAExportHistoryItemStatuses.FAILED
            ? 'error'
            : 'info'
      }
    >
      <NotificationContent item={item} />
    </NotificationWrapper>
  );
};

export default HistoryItem;
