import { TabWrapper, TooltipWrapper } from 'styles/Common.styles';
import text from '../../export-rfa.text.json';
import { FolderOpenS, InfoS } from '@weave-mui/icons-weave';
import MenuItem from '@weave-mui/menu-item';
import Select from '@weave-mui/select';
import Typography from '@weave-mui/typography';
import { SelectWrapper, StackWrapper } from './HistoryTab.styles';
import useDatastore from 'stores/Datastore';
import Box from '@weave-mui/box';
import Button from '@weave-mui/button';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from '@weave-mui/styles';
import useHistoryTab from './useHistoryTab';
import HistoryItem from './HistoryItem/HistoryItem';
import { historyItemsSortOrders } from 'types';
import { historyTabDataTestIds } from 'tests/helpers/dataTestIds';
import { useEffect, useState } from 'react';

const MIN_WINDOW_WIDTH = 450;
const TOOLTIP_PLACEMENT = {
  RIGHT: 'right',
  BOTTOM: 'bottom',
} as const;
type TooltipPlacement = (typeof TOOLTIP_PLACEMENT)[keyof typeof TOOLTIP_PLACEMENT];

const HistoryTab: React.FC = () => {
  const theme = useTheme();
  const { historyItemsSortOrder, rfaExportHistoryItems, isPolling } = useDatastore(
    useShallow((state) => ({
      isPolling: state.isPolling,
      historyItemsSortOrder: state.historyItemsSortOrder,
      rfaExportHistoryItems: state.rfaExportHistoryItems,
    })),
  );

  const { initialDataLoading, handleClearAllExportRecords, handleSort } = useHistoryTab();

  const [tooltipPlacement, setTooltipPlacement] = useState<TooltipPlacement>(TOOLTIP_PLACEMENT.RIGHT);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < MIN_WINDOW_WIDTH) {
        setTooltipPlacement(TOOLTIP_PLACEMENT.BOTTOM);
      } else {
        setTooltipPlacement(TOOLTIP_PLACEMENT.RIGHT);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <TabWrapper>
      {!initialDataLoading && rfaExportHistoryItems.length ? (
        <>
          <Box display="flex" gap="0.5rem">
            <Typography variant="body1Medium" alignSelf="center" data-testid={historyTabDataTestIds.itemsCount}>
              {rfaExportHistoryItems.length}{' '}
              {rfaExportHistoryItems.length === 1 ? text.historyTab.export : text.historyTab.exports}
            </Typography>
            <TooltipWrapper
              data-testid={historyTabDataTestIds.historyItemsTooltip}
              placement={tooltipPlacement}
              size="large"
              arrow
              title={
                <Box display="flex" flexDirection="column" gap="1rem" alignItems="flex-start">
                  <Typography gutterBottom>{text.historyTab.numberOfExportsTooltip}</Typography>
                  <Button
                    data-testid={historyTabDataTestIds.clearExportHistoryButton}
                    color="secondary"
                    variant="outlined"
                    onClick={handleClearAllExportRecords}
                    disabled={isPolling}
                  >
                    {text.historyTab.clearExportHistory}
                  </Button>
                </Box>
              }
            >
              <InfoS color="primary" />
            </TooltipWrapper>
            <SelectWrapper>
              <Select
                data-testid={historyTabDataTestIds.historyItemsSortOrder}
                variant="outlined"
                value={historyItemsSortOrder}
                onChange={handleSort}
                MenuProps={{
                  PaperProps: {
                    sx: { backgroundColor: `${theme.tokens?.semantic['background-color'].surface[100]} !important` },
                  },
                }}
              >
                <MenuItem
                  data-testid={historyTabDataTestIds.historyItemsSortOrderNewestFirst}
                  value={historyItemsSortOrders.NEWEST_FIRST}
                >
                  {text.historyTab.newestFirst}
                </MenuItem>
                <MenuItem
                  data-testid={historyTabDataTestIds.historyItemsSortOrderOldestFirst}
                  value={historyItemsSortOrders.OLDEST_FIRST}
                >
                  {text.historyTab.oldestFirst}
                </MenuItem>
              </Select>
            </SelectWrapper>
          </Box>
          <StackWrapper data-testid={historyTabDataTestIds.historyItems}>
            {rfaExportHistoryItems.map((item) => (
              <HistoryItem key={item.id} item={item} />
            ))}
          </StackWrapper>
        </>
      ) : (
        <>
          {!initialDataLoading && !isPolling && (
            <Box display="flex" justifyContent="center" data-testid={historyTabDataTestIds.emptyItems}>
              <Box textAlign="center">
                <FolderOpenS color="primary" />
                <Typography variant="body2" gutterBottom>
                  {text.historyTab.noExportHisory}
                </Typography>
                <Typography>{text.historyTab.noExportHisoryInfo}</Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </TabWrapper>
  );
};

export default HistoryTab;
