export const propertiesTabDataTestIds = {
  propertiesTab: 'properties-tab',
  fileName: 'file-name',
  fileNameError: 'file-name-error',
  revitVersion: 'revit-version',
  revitVersion2024: 'revit-version-2024',
  revitVersion2025: 'revit-version-2025',
  modelState: 'model-state',
  location: 'location',
  locationSelectButton: 'location-select-button',
  showInLocation: 'show-in-location',
  restoreDefaultPropertiesButton: 'restore-default-properties-button',
  exportButton: 'export-button',
  selectionLocationButton: 'selection-location-button',
  exportInProgressTooltip: 'export-in-progress-tooltip',
};

export const historyTabDataTestIds = {
  historyTab: 'history-tab',
  emptyItems: 'empty-items',
  itemsCount: 'items-count',
  historyItem: 'history-item',
  historyItems: 'history-items',
  historyItemsTooltip: 'history-items-tooltip',
  historyItemsSortOrder: 'history-items-sort-order',
  historyItemsSortOrderNewestFirst: 'history-items-sort-order-newest-first',
  historyItemsSortOrderOldestFirst: 'history-items-sort-order-oldest-first',
  clearExportHistoryButton: 'clear-export-history-button',
  historyItemOptionsButton: 'history-item-options-button',
  historyItemProgressBar: 'history-item-progress-bar',
  deleteHistoryItemButton: 'delete-history-item-button',
  showLogInFolderButton: 'show-log-in-folder-button',
};
