import TabContext from '@weave-mui/tab-context';
import TabList from '@weave-mui/tab-list';
import Tab from '@weave-mui/tab';
import Box from '@weave-mui/box';
import text from '../../export-rfa.text.json';
import { useState } from 'react';
import PropertiesTab from 'components/PropertiesTab/PropertiesTab';
import { TabPanelWrapper } from './Tabs.styles';
import HistoryTab from 'components/HistoryTab/HistoryTab';
import { historyTabDataTestIds, propertiesTabDataTestIds } from 'tests/helpers/dataTestIds';

const Tabs: React.FC = () => {
  const [tabValue, setTabValue] = useState('Properties');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Box height="100%" overflow="hidden" display="flex" flexDirection="column">
      <TabContext value={tabValue}>
        <Box>
          <TabList onChange={handleTabChange}>
            <Tab
              data-testid={propertiesTabDataTestIds.propertiesTab}
              label={text.propertiesTab.title}
              value={text.propertiesTab.title}
            />
            <Tab
              data-testid={historyTabDataTestIds.historyTab}
              label={text.historyTab.title}
              value={text.historyTab.title}
            />
          </TabList>
        </Box>
        <TabPanelWrapper value={text.propertiesTab.title}>
          <PropertiesTab />
        </TabPanelWrapper>
        <TabPanelWrapper value={text.historyTab.title}>
          <HistoryTab />
        </TabPanelWrapper>
      </TabContext>
    </Box>
  );
};

export default Tabs;
