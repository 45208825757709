import { SelectChangeEvent } from '@weave-mui/select';
import { deleteRfaExportHistoryItems, getRfaExportHistoryItems } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useRef, useState, useEffect } from 'react';
import useDatastore from 'stores/Datastore';
import { HistoryItemsSortOrder } from 'types';
import { useShallow } from 'zustand/react/shallow';
import { sortItems } from './utils';

interface UseHistoryState {
  initialDataLoading: boolean;
  handleClearAllExportRecords: () => void;
  handleSort: (event: SelectChangeEvent<HistoryItemsSortOrder>) => void;
}

const useHistoryTab = (): UseHistoryState => {
  const {
    historyItemsSortOrder,
    isPolling,
    rfaExportHistoryItems,
    isHistoryTabVisitedWhilePolling,
    setHistoryItemsSortOrder,
    setRfaExportHistoryItems,
    setIsHistoryTabVisitedWhilePolling,
  } = useDatastore(
    useShallow((state) => ({
      historyItemsSortOrder: state.historyItemsSortOrder,
      isPolling: state.isPolling,
      rfaExportHistoryItems: state.rfaExportHistoryItems,
      isHistoryTabVisitedWhilePolling: state.isHistoryTabVisitedWhilePolling,
      setHistoryItemsSortOrder: state.setHistoryItemsSortOrder,
      setRfaExportHistoryItems: state.setRfaExportHistoryItems,
      setIsHistoryTabVisitedWhilePolling: state.setIsHistoryTabVisitedWhilePolling,
    })),
  );

  const isPollingRef = useRef(isPolling);
  const [initialDataLoading, setInitialDataLoading] = useState(true);

  const handleClearAllExportRecords = async () => {
    try {
      await deleteRfaExportHistoryItems();
      setRfaExportHistoryItems([]);
    } catch (e) {
      logError(e);
    }
  };

  const handleSort = (event: SelectChangeEvent<HistoryItemsSortOrder>) => {
    const newSortOrder: HistoryItemsSortOrder = event.target.value as HistoryItemsSortOrder;
    const sortedItems = sortItems(rfaExportHistoryItems, newSortOrder);

    setRfaExportHistoryItems(sortedItems);
    setHistoryItemsSortOrder(newSortOrder);
  };

  useEffect(() => {
    if (isPolling) {
      setIsHistoryTabVisitedWhilePolling(true);
    }
    setInitialDataLoading(false);
  }, [isPolling, setIsHistoryTabVisitedWhilePolling]);

  // Updates the item list when the polling is complete
  useEffect(() => {
    // We only want to update the history items when the polling is complete,
    // i.e.: when polling has changed from true to false.
    // and when the history tab is visited for the first time.
    const canUpdateHistoryItemStatuses =
      (isPollingRef.current === true && isPolling === false) || (isPolling && isHistoryTabVisitedWhilePolling === false);
    if (canUpdateHistoryItemStatuses) {
      async function fetchInitialData() {
        setInitialDataLoading(true);
        const items = await getRfaExportHistoryItems();
        const sortedItems = sortItems(items, historyItemsSortOrder);
        setRfaExportHistoryItems(sortedItems);
        setInitialDataLoading(false);
      }
      fetchInitialData();
    }

    isPollingRef.current = isPolling;
  }, [historyItemsSortOrder, isHistoryTabVisitedWhilePolling, isPolling, setRfaExportHistoryItems]);

  return {
    initialDataLoading,
    handleClearAllExportRecords,
    handleSort,
  };
};

export default useHistoryTab;
