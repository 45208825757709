import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  activeDocumentContainsSolidGeometry,
  createRfaExportHistoryItem,
  exportActiveDocumentAsBCD,
  exportRfa,
  FileFilter,
  getActiveDocumentInfo,
  getActiveModelStateName,
  getRevitClassification,
  getRfaExportHistoryItem,
  getRfaExportHistoryItems,
  openFileLocation,
  RFAExportHistoryItem,
  RFAExportHistoryItemStatuses,
  saveActiveDocument,
  saveFileDialog,
  updateRfaExportHistoryItem,
  LogLevel,
  logToFile,
  getRFAExportStartMarker,
  getRFAExportEndMarker,
  consolidateRFAExportLogs,
} from 'mid-addin-lib';
import useDatastore from '../../stores/Datastore';
import { ApiUploadFileError, logError, RfaWorkItemError } from 'mid-utils';
import { RFAExportWorkItem, RFAExportWorkItemStatuses } from 'mid-types';
import { LAST_EXPORTED_KEY, validateFileName } from 'utils';
import { RevitVersion } from 'types';

interface UsePropertiesState {
  fileNameError: string | null;
  modelState: string;
  isRevitClassificationValid: boolean;
  isDocumentGeometryValid: boolean;
  handleFileNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRevitVersionChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  handleLocationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectLocation: () => void;
  handleShowInLocationChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleExport: () => void;
}

const usePropertiesTab = (): UsePropertiesState => {
  const {
    fileName,
    revitVersion,
    downloadFolderPath,
    showInLocation,
    defaultProperties,
    isInitialized,
    setIsInitialized,
    setIsHistoryTabVisitedWhilePolling,
    setFileName,
    setRevitVersion,
    setDownloadFolderPath,
    setShowInLocation,
    setDefaultProperties,
    setIsPolling,
    setRfaExportHistoryItems,
  } = useDatastore(
    useShallow((state) => ({
      fileName: state.fileName,
      revitVersion: state.revitVersion,
      downloadFolderPath: state.downloadFolderPath,
      showInLocation: state.showInLocation,
      defaultProperties: state.defaultProperties,
      isInitialized: state.isInitialized,
      setIsHistoryTabVisitedWhilePolling: state.setIsHistoryTabVisitedWhilePolling,
      setFileName: state.setFileName,
      setIsInitialized: state.setIsInitialized,
      setRevitVersion: state.setRevitVersion,
      setDownloadFolderPath: state.setDownloadFolderPath,
      setShowInLocation: state.setShowInLocation,
      setDefaultProperties: state.setDefaultProperties,
      setIsPolling: state.setIsPolling,
      setRfaExportHistoryItems: state.setRfaExportHistoryItems,
    })),
  );

  const [modelState, setModelState] = useState<string>('');
  const [fileNameError, setFileNameError] = useState<string | null>(null);
  const [isRevitClassificationValid, setIsRevitClassificationValid] = useState(true);
  const [isDocumentGeometryValid, setIsDocumentGeometryValid] = useState(true);

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFileName = event.target.value;
    const fileNameError = validateFileName(newFileName);
    setFileNameError(fileNameError);
    setFileName(newFileName);
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDownloadFolderPath(event.target.value);
  };

  const handleSelectLocation = async () => {
    const rfaFilter: FileFilter[] = [{ name: 'Revit Family(*.rfa)', expression: '*.rfa' }];
    const fileLocation = await saveFileDialog(downloadFolderPath, fileName, rfaFilter);
    if (fileLocation) {
      const fileLocationArray = fileLocation.split('\\');
      const fileName = fileLocationArray.pop();
      const downloadFolderPath = fileLocationArray.join('\\');
      if (fileName) {
        // Remove the file extension from the file name
        // but allow for  dots in the file name
        setFileName(fileName.split('.').slice(0, -1).join('.'));
      }
      setDownloadFolderPath(downloadFolderPath);
    }
  };

  const handleRevitVersionChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setRevitVersion(value as RevitVersion);
  };

  const handleShowInLocationChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowInLocation(checked);
  };

  const handleExport = async () => {
    await saveActiveDocument();

    setIsPolling(true);

    let historyItem: RFAExportHistoryItem | null = null;
    let rfaWorkItem: RFAExportWorkItem | null = null;

    try {
      historyItem = await createRfaExportHistoryItem(fileName, revitVersion);

      // mark begin of RFA export related log messages
      if (historyItem) {
        const startMarker: string = await getRFAExportStartMarker(historyItem.id);
        logToFile(startMarker, LogLevel.Info);
      }

      window.localStorage.setItem(LAST_EXPORTED_KEY, JSON.stringify(historyItem));

      logToFile('Exporting active document...', LogLevel.Info);
      const bcdFilePath = await exportActiveDocumentAsBCD();
      logToFile(`Successfully exported active document: '${bcdFilePath}'.`, LogLevel.Info);

      logToFile('Starting Design Automation job...', LogLevel.Info);
      rfaWorkItem = await exportRfa(historyItem, bcdFilePath, revitVersion, downloadFolderPath, fileName);
      logToFile(`Successfully created work item: ${rfaWorkItem.id}`, LogLevel.Info);

      if (showInLocation && rfaWorkItem.status === RFAExportWorkItemStatuses.SUCCESS) {
        const fullPathToFile = `${downloadFolderPath}\\${fileName}.rfa`;
        await openFileLocation(fullPathToFile);
      }
    } catch (error) {
      if (historyItem) {
        await updateRfaExportHistoryItem(historyItem.id, {
          status: RFAExportHistoryItemStatuses.FAILED,
          workItemId: error instanceof RfaWorkItemError ? error.workItemId : '',
          logFilePath: error instanceof RfaWorkItemError ? error.logFilePath : '',
        });
      }

      logError(error);
      logToFile('RFA export failed', LogLevel.Error);

      if (error instanceof ApiUploadFileError) {
        logToFile(error.message, LogLevel.Error);
      } else {
        logToFile('Unknown error', LogLevel.Error);
      }
    } finally {
      setIsPolling(false);
      setIsHistoryTabVisitedWhilePolling(false);
      if (historyItem) {
        const lastExported = await getRfaExportHistoryItem(historyItem.id);
        window.localStorage.setItem(LAST_EXPORTED_KEY, JSON.stringify(lastExported));

        // mark end of RFA export related log messages
        const logEndMarker: string = await getRFAExportEndMarker(historyItem.id);
        logToFile(logEndMarker, LogLevel.Info);

        // merge the client-side and Design Automation logs
        await consolidateRFAExportLogs(historyItem.id);
      }
      const items = await getRfaExportHistoryItems();
      setRfaExportHistoryItems(items);
    }
  };

  useEffect(() => {
    async function validateDocument() {
      const revitClassification = await getRevitClassification();
      setIsRevitClassificationValid(revitClassification.isValid);
      const isDocumentValid = await activeDocumentContainsSolidGeometry();
      setIsDocumentGeometryValid(isDocumentValid);
    }
    validateDocument();
    // Run the validation on focus after bim properties are updated
    window.addEventListener('focus', validateDocument);

    return () => {
      window.removeEventListener('focus', validateDocument);
    };
  }, []);

  useEffect(() => {
    async function fetchInitialData() {
      if (!isInitialized) {
        const documentInfo = await getActiveDocumentInfo();
        const documentName = documentInfo.name.split('.').slice(0, -1).join('.');
        setFileName(documentName);
        setDownloadFolderPath(documentInfo.location);

        setDefaultProperties({
          ...defaultProperties,
          fileName: documentName,
          downloadFolderPath: documentInfo.location,
        });
      }
      if (!modelState) {
        const modelState = await getActiveModelStateName();
        setModelState(modelState);
      }
      setIsInitialized(true);
    }
    fetchInitialData();
  }, [
    defaultProperties,
    isInitialized,
    modelState,
    setDefaultProperties,
    setDownloadFolderPath,
    setFileName,
    setIsInitialized,
  ]);

  return {
    fileNameError,
    modelState,
    isRevitClassificationValid,
    isDocumentGeometryValid,
    handleFileNameChange,
    handleLocationChange,
    handleRevitVersionChange,
    handleSelectLocation,
    handleShowInLocationChange,
    handleExport,
  };
};

export default usePropertiesTab;
