import Box from '@weave-mui/box';
import Button from '@weave-mui/button';
import FormControl from '@weave-mui/form-control';
import FormLabel from '@weave-mui/form-label';
import Radio from '@weave-mui/radio';
import RadioGroup from '@weave-mui/radio-group';
import Checkbox from '@weave-mui/checkbox';
import IconButton from '@weave-mui/icon-button';
import Typography from '@weave-mui/typography';
import SvgIcon from '@weave-mui/svg-icon';
import RevitIcon from '../../assets/Revit_RFA_16.svg?react';
import { LockS, FolderOpenS, InfoS, ErrorS } from '@weave-mui/icons-weave';
import { TabWrapper, TextFieldsWrapper, TooltipWrapper } from '../../styles/Common.styles';
import useDatastore from '../../stores/Datastore';
import { useShallow } from 'zustand/react/shallow';
import usePropertiesTab from './usePropertiesTab';
import { revitVersions } from '../../types';
import { useTheme } from '@weave-mui/styles';
import { CheckBoxWithLabelWrapper } from './PropertiesTab.styles';
import { propertiesTabDataTestIds } from '../../tests/helpers/dataTestIds';
import text from '../../export-rfa.text.json';

export const PropertiesTab: React.FC = () => {
  const {
    fileName,
    downloadFolderPath,
    revitVersion,
    showInLocation,
    isPolling,
    isDefaultProperties,
    restoreDefaultProperties,
  } = useDatastore(
    useShallow((state) => ({
      fileName: state.fileName,
      downloadFolderPath: state.downloadFolderPath,
      revitVersion: state.revitVersion,
      showInLocation: state.showInLocation,
      isPolling: state.isPolling,
      isDefaultProperties: state.isDefaultProperties,
      restoreDefaultProperties: state.restoreDefaultProperties,
    })),
  );

  const theme = useTheme();

  const {
    fileNameError,
    modelState,
    isRevitClassificationValid,
    isDocumentGeometryValid,
    handleLocationChange,
    handleFileNameChange,
    handleRevitVersionChange,
    handleSelectLocation,
    handleExport,
    handleShowInLocationChange,
  } = usePropertiesTab();

  return (
    <TabWrapper>
      <FormControl>
        <FormLabel variant="top">
          <Typography variant="body1Medium">{text.propertiesTab.fileType}</Typography>
        </FormLabel>
        <Box display="flex">
          <SvgIcon>
            <RevitIcon opacity="0.5" />
          </SvgIcon>
          <Typography color="text.disabled" variant="body1Medium">
            {text.propertiesTab.revitFamily}
          </Typography>
        </Box>
      </FormControl>
      <FormControl>
        <FormLabel variant="top">
          <Typography variant="body1Medium">{text.propertiesTab.fileName}</Typography>
        </FormLabel>
        <TextFieldsWrapper
          data-testid={propertiesTabDataTestIds.fileName}
          autoComplete="off"
          variant="outlined"
          value={fileName}
          onChange={handleFileNameChange}
          error={!!fileNameError}
          helperText={
            <Typography data-testid={propertiesTabDataTestIds.fileNameError} variant="caption">
              {fileNameError}
            </Typography>
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="top">
          <Typography variant="body1Medium">{text.propertiesTab.revitVersion}</Typography>
        </FormLabel>
        <RadioGroup
          value={revitVersion}
          onChange={handleRevitVersionChange}
          data-testid={propertiesTabDataTestIds.revitVersion}
        >
          <Box>
            <Radio
              data-testid={propertiesTabDataTestIds.revitVersion2024}
              name={revitVersions[2024]}
              value={revitVersions[2024]}
            />
            <FormLabel htmlFor="">{revitVersions[2024]}</FormLabel>
            <Radio
              data-testid={propertiesTabDataTestIds.revitVersion2025}
              name={revitVersions[2025]}
              value={revitVersions[2025]}
            />
            <FormLabel htmlFor="">{revitVersions[2025]}</FormLabel>
          </Box>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel variant="top">
          <Typography variant="body1Medium">{text.propertiesTab.modelState}</Typography>
        </FormLabel>
        <Box display="flex" gap="1rem" paddingRight={theme.tokens?.semantic.spacing.xxs}>
          <TextFieldsWrapper variant="outlined" disabled value={modelState} fullWidth />
          <TooltipWrapper size="small" arrow title={<Typography>{text.propertiesTab.modelstateTooltip}</Typography>}>
            <Box width={theme.tokens?.component.iconbutton.width} textAlign="center" margin="auto">
              <LockS color="primary" />
            </Box>
          </TooltipWrapper>
        </Box>
      </FormControl>
      <FormControl>
        <FormLabel variant="top">
          <Typography variant="body1Medium">{text.propertiesTab.location}</Typography>
        </FormLabel>
        <Box display="flex" gap="1rem" paddingRight={theme.tokens?.semantic.spacing.xxs}>
          <TextFieldsWrapper
            autoComplete="off"
            variant="outlined"
            value={downloadFolderPath}
            fullWidth
            onChange={handleLocationChange}
            data-testid={propertiesTabDataTestIds.location}
          />
          <IconButton onClick={handleSelectLocation} data-testid={propertiesTabDataTestIds.selectionLocationButton}>
            <FolderOpenS color="primary" />
          </IconButton>
        </Box>
      </FormControl>
      <CheckBoxWithLabelWrapper>
        <Checkbox
          data-testid={propertiesTabDataTestIds.showInLocation}
          checked={showInLocation}
          onChange={handleShowInLocationChange}
        />
        <FormLabel variant="side" htmlFor={text.propertiesTab.showInFileLocation}>
          {text.propertiesTab.showInFileLocation}
        </FormLabel>
      </CheckBoxWithLabelWrapper>
      <Box display="flex" gap="0.5rem" alignSelf="flex-end" marginTop="auto" padding={theme.tokens?.semantic.spacing.xxs}>
        <Button
          variant="outlined"
          disabled={isDefaultProperties()}
          onClick={restoreDefaultProperties}
          data-testid={propertiesTabDataTestIds.restoreDefaultPropertiesButton}
        >
          {text.propertiesTab.restoreDefaultProperties}
        </Button>
        <Button
          variant="outlined"
          onClick={handleExport}
          disabled={
            isPolling ||
            !fileName ||
            !downloadFolderPath ||
            !isRevitClassificationValid ||
            !isDocumentGeometryValid ||
            !!fileNameError
          }
          data-testid={propertiesTabDataTestIds.exportButton}
        >
          {text.propertiesTab.exportButton}
        </Button>
        {isPolling && (
          <TooltipWrapper
            data-testid={propertiesTabDataTestIds.exportInProgressTooltip}
            size="medium"
            arrow
            title={<Typography>{text.propertiesTab.exportInProgressTooltip}</Typography>}
          >
            <InfoS color="primary" />
          </TooltipWrapper>
        )}
        {(!isDocumentGeometryValid || !isRevitClassificationValid) && (
          <>
            {!isDocumentGeometryValid ? (
              <TooltipWrapper size="medium" arrow title={<Typography>{text.propertiesTab.geometryError}</Typography>}>
                <ErrorS color="error" />
              </TooltipWrapper>
            ) : (
              <TooltipWrapper
                size="small"
                arrow
                title={<Typography>{text.propertiesTab.revitClassificationError}</Typography>}
              >
                <ErrorS color="error" />
              </TooltipWrapper>
            )}
          </>
        )}
      </Box>
    </TabWrapper>
  );
};

export default PropertiesTab;
